@import '../../../scss/utilities';

.header {
  margin: 0;
  color: $ue-medium-gray;
  font-family: $ue-subheader-font;
  font-weight: 400;
  font-size: $ue-font-size-8;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    font-size: $ue-font-size-7;
  }
  &_centered {
    text-align: center;
  }
  &_centeredMobile {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  &_padded {
    margin-bottom: $grid-gutter-width * 2;
  }
}
